import React from 'react';
import SidePanelMainButton from './SidePanelMainButton';
import SidePanelSubButton from './SidePanelSubButton';
import breakPoints from 'helpers/Styles';

function SidePanelYourProfile({ onHandleLogout, gotoOrders, gotoProfile, openChat }) {
  return (
    <>
      <div className='your-profile'>YOUR PROFILE</div>
      <div>
        <SidePanelMainButton
          text={'Profile Overview'}
          iconSrc={'/icons/side_panel_icons/user.svg'}
          onClick={gotoProfile}
        />
        <SidePanelMainButton
          text={'Orders'}
          iconSrc={'/icons/side_panel_icons/bag.svg'}
          onClick={gotoOrders}
        />
        <SidePanelMainButton
          text={'Logout'}
          iconSrc={'/icons/side_panel_icons/logout.svg'}
          onClick={onHandleLogout}
        />
      </div>
      <div className="help-support-container">
        <h3 className="help-support">HELP & SUPPORT</h3>
        <SidePanelSubButton
          text={'Live chat'}
          iconSrc={'/icons/message.png'}
          isChatPingingIcon={true}
          onClick={openChat}
        />
        <SidePanelSubButton
          text={'support@sg.aftershockpc.com'}
          iconSrc={'/icons/mail.png'}
          onClick={() =>
            (window.location.href = 'mailto:support@sg.aftershockpc.com')
          }
        />
      </div>
      <style jsx>{`
        .help-support-container {
          font-family: 'Roboto-Medium';
        }
        .help-support {
          font-size: 14px;
        }
        .help-support-list {
          display: flex;
          flex-direction: initial;
          font-size: 14px;
          align-items: center;
          height: 35px;
        }
        .icon {
          width: 15px;
          height: 14px;
        }
        .icon-text {
          margin-left: 3%;
          color: #8a8a8a;
        }
        .your-profile {
          text-align: center;
          padding-bottom: 15px;
          font-size: 13px;
          background: #141414;
          border-bottom: 1px solid rgba(228,228,228,.2);
          display: none;
        }
        @media (max-width: ${breakPoints.small}) {
          .your-profile {
            display: block;
          
          }
        }
      `}</style>
    </>
  );
}

export default SidePanelYourProfile;
