import gql from 'graphql-tag';

const CUSTOMER_RECOVER_MUTATION = gql`
    mutation customerRecover($email: String!) {
        customerRecover(email: $email) {
            customerUserErrors {
                code
                field
                message
            }
        }
    }
`;

export default CUSTOMER_RECOVER_MUTATION;
