import React, { useState } from 'react';
import breakPoints from 'helpers/Styles';
import Router from 'next/router';

const NewsRegister = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);

  Router.onRouteChangeStart = () => {
    setSuccess(false);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      email.match(
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      )
    ) {
      const response = await fetch('/api/shopify/customer/subscribe', {
        method: 'POST',
        body: JSON.stringify({ email }),
      });
      const customer = await response.json();
      if (customer && customer.acceptsMarketing) {
        setError(false);
        setSuccess(true);
      } else {
        setError(true);
        setErrorMessage(
          'There was a problem in subscribing. Please try again later.',
        );
      }
      setEmail('');
    } else {
      setSuccess(false);
      setError(true);
    }
  };
  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <div className="news_register_container">
      <div className="news_register wrapper">
        <div className="news_container">
          <h1>Register your email for exclusive news, offers and giveaways!</h1>
          {success ? <p>You have been successfully subscribed!</p> : null}
          {error ? (
            <p className="error">
              {errorMessage || 'Please enter a valid e-mail address'}
            </p>
          ) : null}
          <div className="news_contents">
            <div className="form_container">
              <form action="">
                <input
                  type="email"
                  placeholder="Your email address"
                  className="email_input_register"
                  onChange={handleChange}
                  value={email}
                />
              </form>
            </div>
            <div className="subscribe_container">
              <a href="/" className="subscribe_button" onClick={handleSubmit}>
                SUBSCRIBE
              </a>
            </div>
          </div>
        </div>
      </div>
      <style jsx>
        {`
          .news_register_container {
            background: #141414;
            min-height: 230px;
          }
          .news_register {
            width: 100%;
            display: flex;
            justify-content: center;
            height: 200px;
            padding-top: 20px;
            padding-bottom: 50px;
            margin-top: 2px;
          }
          .news_container {
            width: 60%;
            text-align: center;
          }
          .news_contents {
            display: flex;
            justify-content: center;
          }
          .form_container {
            width: 70%;
          }
          .subscribe_container {
            width: 200px;
          }
          .news_container h1 {
            font-family: big_noodle_titling;
            color: #e4e4e4;
            font-size: 25px;
            letter-spacing: 0;
            line-height: 32px;
          }
          .email_input_register {
            width: 100%;
            padding: 12px;
            border-radius: 0;
            border: none;
            height: 40px;
            border-radius: 3px 0 0 3px;
            font-family: 'Roboto-Medium';
            font-size: 16px;
            letter-spacing: 0;
            line-height: 24px;
          }
          .subscribe_button {
            display: block;
            height: 40.4px;
            background-color: #950810;
            font-family: 'Roboto-Medium';
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 40px;
            text-align: center;
          }
          .subscribe_button:hover {
            background-color: #800006;
          }
          .error {
            color: #950810;
          }
          @media (max-width: ${breakPoints.small}) {
            .news_register_container {
              min-height: 250px;
            }
            .news_container {
              width: 80%;
            }
            .news_container h1 {
              font-size: 20px;
            }
            .subscribe_container {
              width: 100px;
            }
            .subscribe_button {
              font-size: 13px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default NewsRegister;
