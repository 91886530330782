import Router from 'next/router';
import Image from 'next/image';
import Link from 'next/link';

const ItemCard = ({ item, onClose }) => {
  Router.onRouteChangeStart = () => {
    onClose();
  };
  return (
    <>
      {item?.link && (
        <Link href={item.link || '#'} passHref>
          <div className="item_card">
            {item?.cta_image?.url && (
              <div className="cta-img">
                <Image draggable={false}
                  src={item.cta_image.url}
                  width={120}
                  height={120}
                  alt={item?.cta_image?.alt || 'Product image'}
                />
              </div>
            )}
            {item?.title && <div className="item_title">{item.title}</div>}
            {item?.subtitle && (
              <div className="item_subtitle">{item.subtitle}</div>
            )}
          </div>
        </Link>
      )}

      <style jsx>
        {`
          .item_card {
            width: 120px;
            padding-top: 16px;
            display: flex;
            flex-direction: column;
            align-items: start;
          }
          .item_title {
            padding-top: 4px;
            color: #e4e4e4;
            font-size: 13px;
            line-height: 24px;
          }
          .item_subtitle {
            font-size: 10px;
            line-height: 16px;
            color: #949494;
          }
        `}
      </style>
    </>
  );
};
export default ItemCard;
