/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';

const ErrorConversions = {
  UNIDENTIFIED_CUSTOMER: 'Username is incorrect',
};

const FormSubmitErrors = ({ errors }) => {
  if (!Array.isArray(errors) || errors.length === 0) return null;
  return (
    <div className="form-errors">
      {errors.map((error, key) => {
        const message =
          error.code && ErrorConversions[error.code]
            ? ErrorConversions[error.code]
            : error.message;
        return <p key={key}>{message}</p>;
      })}
      <style jsx>
        {`
          .form-errors {
            max-width: 100%;
            margin-bottom: 10px;
            border: 1px solid rgb(255, 93, 93);
            background-color: rgba(255, 93, 93, 0.16);
          }
          p {
            font-size: 0.9em;
          }
        `}
      </style>
    </div>
  );
};

FormSubmitErrors.propTypes = {
  errors: PropTypes.array.isRequired,
};

export default FormSubmitErrors;
