import React from 'react';
import Script from 'next/script';
const loadChat = () => {
  window.FrontChat('init', {
    chatId: process.env.NEXT_PUBLIC_CHAT_ID,
    useDefaultLauncher: true,
  });
};

export default (
  <>
    <Script
      id="ze-snippet"
      src="https://static.zdassets.com/ekr/snippet.js?key=dd7888f2-8ba5-457a-9129-3e6fe7c14e17"
      strategy='lazyOnload'
    />
  </>
);

