import React from 'react';

function SidePanelMainButton({ iconSrc, text, onClick, chevron }) {
  return (
    <>
      <button className="side_panel_main_button" onClick={onClick}>
        <div className="icon_text">
          <div className="icon">
            {iconSrc ? <img draggable="false" src={iconSrc} alt='side_icon' /> : <div className="no_icon" />}
          </div>
          <span className="button_text">{text}</span>
        </div>
        {chevron == undefined && (
          <img draggable="false" src={'/icons/side_panel_icons/chevron-right-gray.png'} alt='chevron_right'/>
        )}
      </button>

      <style jsx>{`
        .icon {
          width: 36px;
          height: 24px;
          text-align: center;
          margin-left: -6px;
        }
        .icon_text {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 16px;
        }
        .no_icon {
          height: 16px;
          width: 16px;
        }
        .side_panel_main_button {
          background: transparent;
          border: none;
          border-bottom: 1px #242424 solid;
          width: 100%;
          text-align: left;
          padding: 16px 0px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .button_text {
          font-family: 'Roboto-Medium';
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
          color: #e4e4e4;
          text-transform: none;
          margin-left: 12px;
        }
      `}</style>
    </>
  );
}

export default SidePanelMainButton;