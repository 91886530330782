/* eslint-disable react/prop-types */
import Link from 'next/link';
import Image from 'next/image';
import React, { useEffect, useState } from 'react';
import slugify from 'slugify';
import styles from './styles';

const ItemList = ({ item, onClick, checkMegaMenu, index }) => {
  const defaultImage = item?.items[0]?.cta_image?.url || null;
  const [ctaImageUrl, setCtaImageUrl] = useState(null);
  const [pageurl, setPageurl] = useState(null);
  const handleOut = () => {
    setCtaImageUrl(null);
  };

  useEffect(() => {
    checkMegaMenu(index);
  }, [])

  const handleContext = (e) => {
    e.preventDefault();
    window.open(e.target.dataset.pageurl, "_blank")
  }

  return (
    <div className="item-list-container">
      <div className="item-list-title-group">
        <div className="item-list-title">{item?.primary?.title}</div>
        <div className="item-list-subtitle">{item?.primary?.subtitle}</div>
      </div>

      <div className={`item-list-group ${item?.slice_type === '3rd_level' && 'mega-menu-center'}`}>
        {item?.items && (
          <div className={item.slice_type !== '3rd_level' && 'item-list-body'} onMouseLeave={handleOut}>
            <div className="item-list-image">
              {defaultImage && !ctaImageUrl && (
                <div className="cta-img">
                  {item?.slice_type === '3rd_level' ? (
                    <>
                      <Link href={item.items[0].link || '#'}>
                        <div className="rts-image-container" onClick={onClick}>
                          <Image draggable={false} layout="fixed" priority={true} src={defaultImage} width={200} height={180} alt="rts_image" data-pageurl={item.items[0].link}
                            onContextMenu={(e) => handleContext(e)} />
                        </div>
                      </Link>
                    </>
                  ) : (
                    <>
                      <Image draggable={false} priority={true} src={defaultImage} width={120} height={120} alt="default_image" data-pageurl={item.items[0].link}
                        onContextMenu={(e) => handleContext(e)} />
                    </>
                  )}
                </div>
              )}
              {ctaImageUrl && (
                <div className="cta-img">
                  {item?.slice_type === '3rd_level' ? (
                    <Link href={item.items[0].link || '#'}>
                      <div className="rts-image-container" onClick={onClick}>
                        <Image draggable={false} priority={true} src={ctaImageUrl} layout="fixed" width={200} height={180} alt="rts_image" data-pageurl={pageurl}
                          onContextMenu={(e) => handleContext(e)} />
                      </div>
                    </Link>
                  ) : (
                    <Image draggable={false} priority={true} src={ctaImageUrl} width={120} height={120} alt="cta_image" data-pageurl={pageurl}
                      onContextMenu={(e) => handleContext(e)} />
                  )}
                </div>
              )}
            </div>
            <div className="items-list" style={item?.slice_type === '3rd_level' ? { paddingLeft: '0px' } : {}}>
              {item.items.map((productItem, index) => {
                const handleSelect = () => {
                  setCtaImageUrl(productItem?.cta_image?.url);
                  setPageurl(productItem?.link)
                };
                const key = slugify(`item-list-${index}`);
                return (
                  <div className="items-list-title-group" key={key}>
                    {productItem?.link && (
                      <a
                        className="items-list-title"
                        href={productItem.link || '#'}
                        onMouseOver={handleSelect}
                        onClick={onClick}
                        onFocus={() => undefined}
                        aria-hidden="false"
                        style={item?.slice_type === '3rd_level' ? { textAlign: 'center' } : {}}
                      >
                        {productItem?.title}
                      </a>
                    )}

                    <div className="items-list-subtitle">
                      {productItem?.subtitle}
                    </div>
                  </div>
                );
              })}
              {item?.primary?.link && (
                <div aria-hidden="false" className="link-group">
                  <Link href={item.primary.link || '#'} passHref>
                    <div
                      onClick={onClick}
                      aria-hidden="false"
                      onFocus={() => undefined}
                      className="link-button"
                    >
                      Shop all
                    </div>
                  </Link>
                  <Image draggable={false}
                    height={16}
                    width={16}
                    src="/icons/arrow-right-red.svg"
                    alt="link-button"
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <style jsx>{styles}</style>
    </div>
  );
};
export default ItemList;
