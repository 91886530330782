import React, { useState, useEffect, memo, useContext } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { getCheckoutId } from 'helpers/cookies';
import { StorefrontContext } from 'providers/storefront';
import Image from 'next/image';
import CountrySelector from 'components/CountrySelector';
import Logo from 'components/Logo';
import styles from './styles';
import CartCounter from './CartCounter';
import Primary from './Primary';
import Countdown from 'react-countdown';
import RegionSwitchButton from 'components/NavigationButtonIcons/RegionSwitchButton';
import ProfileButton from 'components/NavigationButtonIcons/ProfileButton';
import { useRouter } from 'next/router';
import AccountPrimary from './AccountPrimary';
import BannerCountdownTimer from '../BannerCountdownTimer';

const Navigation = ({ header, opacityRef }) => {
  const router = useRouter();
  const [kspItems, setKspsItems] = useState([]);
  const [quizItems, setQuizItems] = useState();
  const [checkoutId, setCheckoutId] = useState(getCheckoutId());
  const { setIsCartOpen, checkout, isLoggedIn, setIsLoggedIn } =
    useContext(StorefrontContext);
  const { setIsAnnouncementBarOpen, isAnnouncementBarOpen } =
    useContext(StorefrontContext);
  const [menuItems, setMenuItems] = useState([]);
  useEffect(() => {
    if (checkout?.id) setCheckoutId(checkout.id);
  }, [checkout]);

  const getMegaMenuColumns = (megaMenuItems) => {
    if (!megaMenuItems) return [];
    const columns = [];
    let currentSecondLevelIndex = 0;
    megaMenuItems.forEach((childMenuItem, index) => {
      const sliceType = childMenuItem.slice_type;
      // eslint-disable-next-line no-plusplus
      if (sliceType === '1st_level' && index !== 0) currentSecondLevelIndex++;
      if (!columns[currentSecondLevelIndex]) {
        columns[currentSecondLevelIndex] = [];
      }

      columns[currentSecondLevelIndex].push(childMenuItem);
    });

    return columns;
  };
  useEffect(() => {
    if (header && header.body) {
      setMenuItems(getMegaMenuColumns(header.body));
    }
  }, [header]);
  useEffect(() => {
    if (header && header.body) {
      const ksps = header.body.filter((n) => n.slice_type === 'ksps');
      const quiz = header.body.filter((n) => n.slice_type === 'quiz');
      const items = ksps;
      setKspsItems(items);
      setQuizItems(quiz);
    }
  }, [header]);
  const handleChatToggle = () => {
    console.log(window.$zopim.livechat)
    let devices = window.navigator.userAgent.toLowerCase()
    if (devices.includes('android') || devices.includes('mobile')) {
      window.$zopim.livechat.window.show()
    }
    else window.$zopim.livechat.window.toggle()
    // window.FrontChat('show')
  }

  const handleCartOpen = () => {
    setIsCartOpen(true);
  };

  const handleCloseBanner = () => {
    setIsAnnouncementBarOpen(false);
  };
  const topBanner =
    header &&
    header?.body?.filter((item) => item?.slice_type === 'shipping_banner');

  const topBannerTimer =
    header &&
    header?.body?.filter((item) => item?.slice_type === 'shipping_banner');

  const topBannerContents = topBanner && topBanner[0]?.primary?.banner_contents;

  const topBannerTimerContents =
    topBannerTimer && topBannerTimer[0]?.primary?.countdown_end;

  const renderer = ({
    days = null,
    hours = null,
    minutes = null,
    seconds = null,
    completed = null,
  }) => {
    if (completed) {
      return <></>;
    } else {
      return (
        <span>
          {' '}
          | Ends in {days}d {hours}h {minutes}m {seconds}s
        </span>
      );
    }
  };

  return (
    <>
      <nav>
        {isAnnouncementBarOpen && topBannerContents && (
          <div className="shipping-banner">
            <div className="wrapper shipping-banner">
              {topBannerTimerContents ? (
                <>
                  {topBannerContents}
                  {/* <Countdown
                    renderer={renderer}
                    date={new Date(topBannerTimerContents)}
                  /> */}
                  <BannerCountdownTimer date={topBannerTimerContents} />
                </>
              ) : (
                topBannerContents
              )}
              <div
                className="close_banner"
                onClick={handleCloseBanner}
                aria-hidden="false"
              >
                <Image draggable={false} src="/icons/icon-close.svg" width="16" height="16" alt="close_icon"/>
              </div>
            </div>
          </div>
        )}
      </nav>
      <nav className="header-nav">
        <div className="top_nav">
          <div className="menu_container">
            <div className="wrapper">
              <div className="nav-logo">
                <Logo width={148} height={32} />
              </div>
              {/* {['/me', '/me/sign-up'].includes(router.pathname) ? (
                <AccountPrimary
                  ksps={kspItems}
                  quiz={quizItems}
                  data={menuItems}
                  opacityRef={opacityRef}
                />
              ) : (
                <Primary
                  ksps={kspItems}
                  quiz={quizItems}
                  data={menuItems}
                  opacityRef={opacityRef}
                />
              )} */}
              <Primary
                ksps={kspItems}
                quiz={quizItems}
                data={menuItems}
                opacityRef={opacityRef}
              />
              <div className="icon_section">
                {/* <CountrySelector backgroundColor="#242424" isShowFullName /> */}
                <RegionSwitchButton />
                <button className="chat_icon" onClick={handleChatToggle}>
                  <Image draggable={false}
                    src={'/icons/icon-chat.svg'}
                    alt={'icon-chat'}
                    width={18}
                    height={18}
                  />
                  <span className="chat_badge" />
                  <span className="chat_badge ping" />
                </button>
                <ProfileButton
                  isLoggedIn={isLoggedIn}
                  setIsLoggedIn={setIsLoggedIn}
                />
                {/* <div>
                  <Link href="/me">
                    <a href="/me">
                      <div className="profile_icon">
                        <Image draggable={false}
                          src={
                            isLoggedIn
                              ? '/icons/icon-profile-logged-in.svg'
                              : '/icons/icon-profile.svg'
                          }
                          alt="cart icon"
                          className="cart_icon"
                          layout="fixed"
                          width={24}
                          height={24}
                        />
                      </div>
                    </a>
                  </Link>
                </div> */}

                <div
                  type="button"
                  className="cart cart_container"
                  onClick={handleCartOpen}
                  aria-hidden="false"
                >
                  <Image draggable={false}
                    src="/icons/icon-cart.svg"
                    alt="cart icon"
                    className="cart_icon"
                    layout="fixed"
                    width={24}
                    height={24}
                  />
                  <span>
                    {checkoutId && <CartCounter checkoutId={checkoutId} />}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <style jsx>{styles}</style>
     
    </>
  );
};

Navigation.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  header: PropTypes.any,
};

Navigation.defaultProps = {
  header: null,
};

export default memo(Navigation);
