export const EVENT_DEBUG = process.env.NEXT_PUBLIC_EVENT_DEBUG;

export const infoLog = (message) => {
  console.log(
    '%c%s',
    'color: white; background: green; padding: 2px 10px;',
    message,
  );
};

export const eventLog = (message, customEvent) => {
  console.log(
    '%c%s',
    'color: white; background: green; padding: 2px 10px;',
    message,
    customEvent ? customEvent.detail : 'No event details available',
  );
};
