/* eslint-disable react/prop-types */
import React, { memo } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import Router from 'next/router';
import slugify from 'slugify';
import ItemCard from '../ItemCard';

const FourthLevelMobile = ({ items, onClose }) => {
  Router.onRouteChangeStart = () => {
    onClose();
  };
  return (
    <div className="category_navigation__fourth">
      <div className="fourth_menu">
        <div className="wrapper fourth_menu_header">
          {items?.primary?.title && (
            <div className="fourth_menu_header_title">
              {items?.primary?.title}
            </div>
          )}
          {/* {items?.primary?.subtitle && (
            <div className="fourth_menu_header_title">
              {items?.primary?.subtitle}
            </div>
          )} */}
          {items?.primary?.link && (
            <div aria-hidden="false" className="link-group">
              <Link href={items.primary.link || '#'} passHref>
                <div className="link-button">Shop all</div>
              </Link>
              <Image draggable={false}
                height={16}
                width={16}
                src="/icons/arrow-right-red.svg"
                alt="link-button"
              />
            </div>
          )}
        </div>
        <div className="wrapper fourth_menu_item_body">
          {items?.items.map((item, index) => {
            const key = slugify(`item-${index}`);
            return (
              <div key={key}>
                <ItemCard item={item} onClose={onClose} />
              </div>
            );
          })}
        </div>
      </div>
      <style jsx>
        {`
          .link-button {
            color: #fd0300;
            font-size: 13px;
            line-height: 20px;
            padding-right: 7px;
            cursor: pointer;
          }
          .link-group {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          .fourth_menu {
            width: 100%;
            padding: 24px 0;
            border-bottom: 1px solid rgba(228, 228, 228, 0.2);
          }
          .fourth_menu_header {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .fourth_menu_item_body {
            display: flex;
            gap: 24px;
            overflow-x: scroll;
            -ms-overflow-style: none; /* for Internet Explorer, Edge */
            scrollbar-width: none; /* for Firefox */
          }
          .fourth_menu_item_body::-webkit-scrollbar {
            display: none; /* for Chrome, Safari, and Opera */
          }
        `}
      </style>
    </div>
  );
};

export default memo(FourthLevelMobile);
