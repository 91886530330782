import css from "styled-jsx/css";

export default css`
  .primary_menu {
    display: flex;
    margin: 0;
    padding: 0;
    font-size: 13px;
    line-height: 24px;
    align-items: center;
  }
  .top-level {
    height: 48px;
    display: flex;
    color: #e4e4e4;
    letter-spacing: 0;
    list-style: none;
    opacity: 0.75;
  }
  .top-level-title {
    border-bottom: 2px solid #242424;
    text-transform: uppercase;
  }
  .top-level a {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 20px;
  }
  li:first-child {
    margin-left: -38px;
  }

  .top-level:hover {
    opacity: 1;
  }
  .top-level-2 {
    border-bottom: 2px solid #fd0300;
  }
  .top-level-activated {
    border-bottom: 2px solid #fd0300;
  }
  .menu_icon_light,
  .menu_icon {
    margin-left: 8px;
  }
  .menu_icon_light {
    display: none;
  }
  .top-level:hover a > .menu_icon_light {
    display: block;
  }
  .top-level:hover a > .menu_icon {
    display: none;
  }
  .icon_show {
    display: block;
  }
  .icon_hidden {
    display: none;
  }
  @media (max-width: 1109px) {
    .primary_menu .top-level-title {
      line-height: initial;
    }
  }
`;
