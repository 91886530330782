import React from 'react';

function SidePanelButtonLink({ onClick, text }) {
  return (
    <>
      <button className="main" onClick={onClick}>
        {text}
      </button>
      <style jsx>{`
        .main {
          font-family: 'Roboto-Medium';
          font-size: 13px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          text-decoration-line: underline;
          margin-top: 12px;
          background: transparent;
          border: none;
          color: #e4e4e4;
          text-transform: none;
        }
      `}</style>
    </>
  );
}

export default SidePanelButtonLink;