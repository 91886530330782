import { useState } from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import breakPoints from 'helpers/Styles';
import Image from 'next/image';
import Copyright from './Copyright';
import NewsRegister from './NewsRegister';

function Footer({ footer }) {
  const nav = footer ? footer.body : [];
  const [clickIndex, setClickIndex] = useState(null);
  const lastMenuItem = nav.length + 1;
  const onChangeSelectedMenu = (index) => {
    if (clickIndex === index) {
      setClickIndex(null);
      return;
    }
    setClickIndex(index);
  };
  return (
    <div className="hide_for_rts">
      <div className="footer" id="footer">
        <NewsRegister />
        <div className="footer_container">
          <div className="wrapper">
            <div className="prismic_footer_links">
              {nav.map((item, key) => {
                const navItem = item && item.items ? item.items : [];
                return (
                  <div key={key} className="links_column">
                    <ul>
                      <li className="footer-title">
                        {item.primary && item.primary.column_title
                          ? item.primary.column_title
                          : ''}
                      </li>

                      {navItem.map((item, key) => (
                        <li key={key} className="footer_link">
                          <Link href={item.link || '#'}>
                            <a href={item.link || '#'}>{item.link_title}</a>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                );
              })}
            </div>

            <div className="contact_us_container">
              <ul>
                <li className="footer-title">CONTACT US</li>
                <li className="address">
                  AFTERSHOCK FLAGSHIP SHOWROOM: <br />
                  {/* 994 Bendemeer Road #05-07 S339943 */}
                  <a
                    href="https://goo.gl/maps/daw6QWCSeAfdhLsY8"
                    target="_blank"
                  >
                    E-02-11 Sunway Geo Avenue, Jalan Lagoon Selatan, Bandar Sunway 47500 Petaling Jaya Selangor
                  </a>
                </li>
                <li className="address">
                    OPENING HOURS: <br />
                    Monday - Saturday: 10:30am - 7:00pm
                    Closed on Sundays and Public Holidays
                  </li>
              </ul>
              <div className="social_links_desktop">
                <a
                  className="facebook"
                  href="https://www.facebook.com/AftershockPC/"
                  target="_blank"
                  rel="facebook noreferrer"
									aria-label='facebook'
                />

                <a
                  className="instagram"
                  href="https://www.instagram.com/aftershockpc/"
                  rel="instagram noreferrer"
                  target="_blank"
									aria-label='instagram'
                />

                <a
                  className="youtube"
                  href="https://www.youtube.com/c/AFTERSHOCKPCsg"
                  target="_blank"
                  rel="youtube"
									aria-label='youtube'
                />
                <a
                  className="pinterest"
                  href="https://pin.it/25oDqj6"
                  target="_blank"
                  rel="pinterest"
									aria-label='pinterest'
                />
              </div>
            </div>
          </div>
        </div>

        <div className="panel hidden_on_desktop">
          <div className="accordion_container">
            {nav.map((item, index) => {
              const navItem = item && item.items ? item.items : [];
              return (
                <div key={index} className="column">
                  <div
                    className="footer-title"
                    onClick={() => onChangeSelectedMenu(index)}
                  >
                    <div>
                      {item.primary && item.primary.column_title
                        ? item.primary.column_title
                        : ''}
                    </div>
                    <div>
                      <img draggable="false"
                        src={
                          clickIndex === index
                            ? '/icons/icon_up_light.svg'
                            : '/icons/icon_down_light.svg'
                        }
                        alt="first_level menu"
                      />
                    </div>
                  </div>

                  {clickIndex === index
                    ? navItem.map((item, key) => (
                        <li key={key} className="footer_link">
                          <Link href={item.link}>
                            <a href={item.link} title={item.link_title}>
                              {item.link_title}
                            </a>
                          </Link>
                        </li>
                      ))
                    : null}
                </div>
              );
            })}

            <div key={lastMenuItem} className="column">
              <div
                className="footer-title"
                onClick={() => onChangeSelectedMenu(lastMenuItem)}
              >
                <div>Contact Us</div>
                <div>
                  <img draggable="false"
                    src={
                      clickIndex === lastMenuItem
                        ? '/icons/icon_up_light.svg'
                        : '/icons/icon_down_light.svg'
                    }
                    alt="first_level menu"
                  />
                </div>
              </div>

              {clickIndex === lastMenuItem ? (
                <ul className="contact_container">
                  <li className="address">
                    AFTERSHOCK FLAGSHIP SHOWROOM: <br />
                    <a
                      href="https://goo.gl/maps/daw6QWCSeAfdhLsY8"
                      target="_blank"
                    >
                      E-02-11 Sunway Geo Avenue, Jalan Lagoon Selatan, Bandar Sunway 47500 Petaling Jaya Selangor
                    </a>
                  </li>
                  <li className="address">
                    OPENING HOURS: <br />
                    Monday - Saturday: 10:30am - 7:00pm
                    Closed on Sundays and Public Holidays
                  </li>
                </ul>
              ) : null}
            </div>

            <div className="social_links">
              <div>
                <div>
                  <a
                    href="https://www.facebook.com/AftershockPC/"
                    target="_blank"
                    rel="noreferrer"
										aria-label="facebook"
                  >
                    <Image draggable={false}
                      src="/icons/facebook-24px.svg"
                      alt="facebook icon"
                      width={24}
                      height={24}
                    />
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.instagram.com/aftershockpc/"
                    target="_blank"
                    rel="noreferrer"
										aria-label="instagram"
                  >
                    <Image draggable={false}
                      src="/icons/instagram-24px.svg"
                      alt="instagram icon"
                      width={24}
                      height={24}
                    />
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.youtube.com/c/AFTERSHOCKPCsg"
                    target="_blank"
                    rel="noreferrer"
										aria-label="youtube"
                  >
                    <Image draggable={false}
                      src="/icons/youtube-24px.svg"
                      alt="youtube icon"
                      width={24}
                      height={24}
                    />
                  </a>
                </div>
                <div>
                  <a
                    href="https://pin.it/25oDqj6"
                    target="_blank"
                    rel="noreferrer"
										aria-label="pinterest"
                  >
                    <Image draggable={false}
                      src="/icons/pinterest.png"
                      alt="Pinterest icon"
                      width={24}
                      height={24}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Copyright />
      </div>
      <style jsx>
        {`
          .footer {
            width: 100%;
            color: white;
            background: black;
          }
          .footer_container {
            width: 100%;
            background: #242424;
            padding: 50px 0px;
          }
          .footer_container .wrapper {
            display: flex;
          }
          .prismic_footer_links {
            display: flex;
            flex-flow: row;
            flex: 2;
          }
          .prismic_footer_links ul {
            padding: 0;
          }
          .contact_container {
            margin: 0;
            padding: 0;
          }
          .links_column {
            width: 25%;
          }
          .accordion_container {
            width: 100%;
          }
          .column {
            width: 100%;
            line-height: 60px;
            padding-left: 20px;
            background: #242424;
            border-bottom: 1px solid black;
          }
          .contact_us_container {
            flex: 1;
          }
          .social_links_desktop {
            display: flex;
            padding-left: 40px;
            gap: 3%;
          }

          .youtube {
            display: block;
            background-image: url('/icons/youtube-24px.svg');
            width: 24px;
            height: 24px;
            cursor: pointer;
          }
          .youtube:hover {
            background-image: url('/icons/Youtube-hover.svg');
          }
          .facebook {
            display: block;
            background-image: url('/icons/facebook-24px.svg');
            width: 24px;
            height: 24px;
            cursor: pointer;
          }
          .facebook:hover {
            background-image: url('/icons/Facebook-hover.svg');
          }
          .twitter {
            display: block;
            background-image: url('/icons/twitter-24px.svg');
            width: 24px;
            height: 24px;
            cursor: pointer;
          }
          .twitter:hover {
            background-image: url('/icons/Twitter-hover.svg');
          }
          .pinterest {
            display: block;
            background-image: url('/icons/pinterest.png');
            width: 24px;
            height: 24px;
            cursor: pointer;
          }
          .pinterest:hover {
            background-image: url('/icons/pinterest-hover.png');
          }
          .instagram {
            display: block;
            background-image: url('/icons/instagram-24px.svg');
            width: 24px;
            height: 24px;
            cursor: pointer;
          }
          .instagram:hover {
            background-image: url('/icons/Instagram-hover.svg');
          }
          li {
            color: #949494;
            font-family: 'Roboto-Medium';
            font-size: 16px;
            letter-spacing: 0;
            line-height: 40px;
            list-style: none;
          }
          .footer_link:hover {
            color: #e4e4e4 !important;
          }
          .contact_phone_number .address {
            color: white;
          }
          .address {
            font-family: 'Roboto-Medium';
          }
          h3 {
            font-family: 'DINPro-Bold' !important;
          }
          .footer-title {
            font-family: 'big_noodle_titling' !important;
            font-size: 25px;
            letter-spacing: 0;
            line-height: 32px;
            color: #e4e4e4;
            font-weight: 300;
            height: 60px;
            padding-top: 15px;
            display: flex;
            justify-content: space-between;
            padding-right: 20px;
            cursor: pointer;
          }
          .hidden_on_desktop {
            display: none;
            width: 100%;
            // padding-top: 50px;
            margin-bottom: 30px;
          }
          .hidden_on_desktop .items {
            padding-left: 0px;
          }
          .hidden_on_desktop .items li a {
            color: white;
            font-size: 18px;
          }
          .social_links {
            height: 72px;
            background: #242424;
            display: flex;
            justify-content: center;
            algin-items: center;
          }
          .social_links div {
            width: 50%;
            margin: 0 auto;
            justify-content: center;
            align-items: center;
            display: flex;
          }
          .social_links img {
            height: 35px;
            margin-right: 15px;
          }
          .address a:hover {
            color: #e4e4e4;
          }
          @media (max-width: 330px) {
            .accordion_container {
              width: 90%;
            }
          }

          @media (max-width: ${breakPoints.medium}) {
            .footer {
              display: flex;
              flex-direction: column;
              padding: 0;
            }
            .footer_container {
              display: none;
            }
            .hidden_on_desktop {
              width: 100%;
              display: flex !important;
              justify-content: center;
              // padding-top: 20px;
            }
            .contact_us_container {
              width: 100%;
              display: block;
              justify-content: center;
              min-height: 350px;
            }
          }
          @media (max-width: ${breakPoints.small}) {
            .subscribe_button {
              font-size: 13px;
            }
          }
        `}
      </style>
    </div>
  );
}

Footer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  footer: PropTypes.any,
};

Footer.defaultProps = {
  footer: null,
};

export default Footer;
