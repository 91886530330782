import React, { useEffect, useState } from 'react';
import SidePanelButtonLink from './SidePanelButtonLink';
import SidePanelCheckboxInput from './SidePanelCheckboxInput';
import SidePanelFormButton from './SidePanelFormButton';
import SidePanelTextField from './SidePanelTextField';
import * as Yup from 'yup'
import { Formik, Form, Field } from 'formik';
import FormSubmitErrors from 'components/Form/FormSubmitErrors';
import {
  CUSTOMER_CREATE_MUTATTION,
  customerCreateVars,
  CUSTOMER_LOGIN_MUTATION,
  customerLoginVars,
} from 'helpers/customer';
import { setCustomerToken as setCustomerTokenCookie } from 'helpers/cookies';
import { useMutation } from '@apollo/client';


function SidePanelCreateProfileForm({ onHandleLogin, onCreateProfile: onSuccessfulCreateProfile }) {
  const [customerCreate, { loading: createProfileLoading, error: createProfileError, data: createProfileData }] = useMutation(
    CUSTOMER_CREATE_MUTATTION,
  );
  const [customerLogin, { error: loginError, data: loginData }] = useMutation(CUSTOMER_LOGIN_MUTATION);

  const [isSubscribed, setIsSubscribed] = useState(false);

  const SignupSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
    firstName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
    lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
    password: Yup.string().min(8, 'Too Short!').max(50, 'Too Long!').required('Required'),
    confirmPassword: Yup.string().required('Required').oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });

  const onCreateProfile = (values) => {
    customerCreate({
      variables: customerCreateVars(values),
      // eslint-disable-next-line no-shadow
      update(proxy, { data: { customerCreate } }) {
        if (customerCreate.customer && customerCreate.customer.id) {

          // Trigger Sign In
          customerLogin({
            variables: customerLoginVars(values),

            update(proxy, { data: { customerAccessTokenCreate } }) {

              const { customerAccessToken } = customerAccessTokenCreate;
              if (customerAccessToken && customerAccessToken.accessToken) {
                const { accessToken, expiresAt } = customerAccessToken;
                setCustomerTokenCookie({ accessToken, expiresAt });
                onSuccessfulCreateProfile()
              }
            }
          })

        }
      },
    });
  };
 
  return (
    <>
      <div>
        <Formik
          initialValues={{
            email: '',
            firstName: '',
            lastName: '',
            password: '',
            confirmPassword: '',
          }}
          validationSchema={SignupSchema}
          onSubmit={(values) => {
            const { firstName, lastName, email, password } = values;

            onCreateProfile({ firstName, lastName, email, password });
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <p className="create_profile_text">
                Just a few more details and you’re all set up
              </p>
              {createProfileData &&
              createProfileData.customerCreate.customerUserErrors ? (
                <FormSubmitErrors
                  errors={createProfileData.customerCreate.customerUserErrors}
                />
              ) : null}
              {createProfileError || loginError ? (
                <FormSubmitErrors
                  errors={[
                    { message: 'Something went wrong. Please try again.' },
                  ]}
                />
              ) : null}
              <div className="inputs">
                <Field
                  name="email"
                  component={SidePanelTextField}
                  placeholder="Email"
                />
                <Field
                  name="firstName"
                  component={SidePanelTextField}
                  placeholder="First Name"
                />
                <Field
                  name="lastName"
                  component={SidePanelTextField}
                  placeholder="Last Name"
                />
                <Field
                  name="password"
                  component={SidePanelTextField}
                  placeholder="Password"
                  type="password"
                />
                <Field
                  name="confirmPassword"
                  component={SidePanelTextField}
                  placeholder="Confirm Password"
                  type="password"
                />
              </div>
              <div className="checkbox_container">
                <SidePanelCheckboxInput
                  label={'Keep me up to date with news and offers'}
                  value={isSubscribed}
                  onChange={(val) => setIsSubscribed(val)}
                />
              </div>
              <div className="button_container">
                <SidePanelFormButton text="Create Profile" type="submit" />
              </div>
              <SidePanelButtonLink
                text={'Already have an account? Login here'}
                onClick={onHandleLogin}
              />
            </Form>
          )}
        </Formik>
      </div>

      <style jsx>{`
        .checkbox_container {
          margin-top: 26px;
        }
        .inputs {
          margin-top: 15px;
          display: grid;
          gap: 16px;
        }
        .create_profile_text {
          padding: 0;
          margin: 0;
          font-family: 'Roboto-Medium';
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
        }
        .button_container {
          margin-top: 26px;
        }
      `}</style>
    </>
  );
}

export default SidePanelCreateProfileForm;
