import gql from 'graphql-tag';
import { useEffect } from 'react';

export const CheckoutFragment = gql`
  fragment CheckoutFragment on Checkout {
    id
    webUrl
    completedAt
    email
    totalTax {
      amount
      currencyCode
    }
    subtotalPrice {
      amount
      currencyCode
    }
    totalPrice {
      amount
      currencyCode
    }
    lineItems(first: 20) {
      edges {
        node {
          id
          title
          quantity
          variant {
            id
            title
            product {
              handle
              tags
              productType
            }
            image {
              src
            }
            price {
              amount
              currencyCode
            }
            compareAtPrice {
              amount
              currencyCode
            }
          }
          quantity
          customAttributes {
            key
            value
          }
        }
      }
    }
  }
`;

export const CHECKOUT_QUERY = gql`
  query checkout($checkoutId: ID!) {
    node(id: $checkoutId) {
      ... on Checkout {
        ...CheckoutFragment
      }
    }
  }
  ${CheckoutFragment}
`;

export const checkoutById = (checkoutId) => ({
  checkoutId,
});

export const createCheckout = gql`
  mutation checkoutCreate($input: CheckoutCreateInput!) {
    checkoutCreate(input: $input) {
      userErrors {
        message
        field
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
  ${CheckoutFragment}
`;

export const checkoutLineItemsAdd = gql`
  mutation checkoutLineItemsAdd(
    $checkoutId: ID!
    $lineItems: [CheckoutLineItemInput!]!
  ) {
    checkoutLineItemsAdd(checkoutId: $checkoutId, lineItems: $lineItems) {
      userErrors {
        message
        field
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
  ${CheckoutFragment}
`;

export const checkoutLineItemsUpdate = gql`
  mutation checkoutLineItemsUpdate(
    $checkoutId: ID!
    $lineItems: [CheckoutLineItemUpdateInput!]!
  ) {
    checkoutLineItemsUpdate(checkoutId: $checkoutId, lineItems: $lineItems) {
      userErrors {
        message
        field
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
  ${CheckoutFragment}
`;

export const checkoutLineItemsRemove = gql`
  mutation checkoutLineItemsRemove($checkoutId: ID!, $lineItemIds: [ID!]!) {
    checkoutLineItemsRemove(
      checkoutId: $checkoutId
      lineItemIds: $lineItemIds
    ) {
      userErrors {
        message
        field
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
  ${CheckoutFragment}
`;

export function useCheckoutEffect(data, key, setDataCallback) {
  useEffect(() => {
    if (data && data[key] && data[key].checkout) {
      setDataCallback(data[key].checkout);
    }
  }, [data]);
}

export const addToCartVars = ({ variantId, quantity, customAttributes }) => ({
  input: {
    lineItems: [
      {
        variantId,
        quantity,
        customAttributes,
      },
    ],
  },
});
export const addToCartItemsVars = (values) => ({
  input: {
    lineItems: values,
  },
});

export const checkoutAddLineItemVars = ({
  variantId,
  quantity,
  checkoutId,
  customAttributes,
}) => ({
  checkoutId,
  lineItems: [
    {
      variantId,
      quantity,
      customAttributes,
    },
  ],
});
export const checkoutAddLineItemsVars = ({ values, checkoutId }) => {
  return {
    checkoutId,
    lineItems: values,
  };
};

export const CHECKOUT_ASSOCIATE_CUSTOMER = gql`
  ${CheckoutFragment}
  mutation checkoutCustomerAssociateV2(
    $checkoutId: ID!
    $customerAccessToken: String!
  ) {
    checkoutCustomerAssociateV2(
      checkoutId: $checkoutId
      customerAccessToken: $customerAccessToken
    ) {
      checkout {
        ...CheckoutFragment
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`;
