import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const httpLink = createHttpLink({
  uri: process.env.NEXT_PUBLIC_ECOMMERCE_GQL_URL,
});

const middlewareLinkAU = setContext(() => ({
  headers: {
    'X-Shopify-Storefront-Access-Token':
      process.env.NEXT_PUBLIC_ECOMMERCE_GQL_TOKEN,
  },
}));

const client = new ApolloClient({
  link: middlewareLinkAU.concat(httpLink),
  cache: new InMemoryCache(),
});
export default client;
