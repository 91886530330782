export const fGetLastBreadcrumb = (breadcrumbs) => {
  const breadcrumbsArray = breadcrumbs;
  const lastBreadcrumb = breadcrumbsArray
    ? breadcrumbsArray[breadcrumbsArray?.length - 1]
    : 0;
  return lastBreadcrumb;
};

export const fGetUniqueValue = (value, index, self) => {
  return self.indexOf(value) === index;
};

export const fGetPlural = ({ number, plural, singular }) => {
  if (number > 1) {
    return number + '' + plural;
  } else {
    return number + '' + singular;
  }
};