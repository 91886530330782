import PropTypes from 'prop-types';

const PreviewMode = ({ preview }) => {
  if (!preview) return '';
  return (
    <>
      <div className="previewMode">
        <div className="previewModecontent wrapper">
          <p>Preview Mode On</p>
          <a href="/api/exit-preview" className="button">
            Exit Preview
          </a>
        </div>
      </div>
      <style jsx>
        {`
          .previewMode {
            position: fixed;
            bottom: 0;
            width: 100%;
            z-index: 4;
            background-color: #fff;
            color: #000;
            font-size: 24px;
            letter-spacing: 0.5px;
          }
          .previewModecontent {
            display: flex;
            justify-content: space-between;
            padding: 20px;
          }
          a {
            font-size: 24px;
            border: 1px solid #000;
            border-radius: 15px;
            padding: 10px;
          }
          p {
            margin: 0;
            padding: 10px;
          }
        `}
      </style>
    </>
  );
};

PreviewMode.propTypes = {
  preview: PropTypes.bool,
};

PreviewMode.defaultProps = {
  preview: false,
};

export default PreviewMode;
