/* global window */
import React, { useState } from 'react';
import Image from 'next/image';
import PropTypes from 'prop-types';
import styles from './styles';

const CountrySelector = ({ backgroundColor, isShowFullName, mobileView }) => {
  const [showAll, setShowAll] = useState(false);

  const toggleSelector = () => {
    setShowAll(!showAll);
  };

  const handleMalasia = () => {
    setShowAll(false);
    window.open('https://www.level51pc.co/');
  };
  const handleAustralia= ()=>{
    setShowAll(false);
    window.open('https://www.aftershockpc.com.au/');
  }
  const handleSingapore = () => {
    setShowAll(false);
    window.open('https://www.aftershockpc.com/');
  };

  return (
    <div className="selector_wrapper">
      <ul className={showAll ? 'open' : 'close'}>
        {mobileView && (
          <li className="default">
            <button
              className="button-div"
              type="button"
              onClick={toggleSelector}
            >
              {/* <Image draggable={false}
              src="/images/au-flag.jpg"
              alt="Australia Flag"
              width="18px"
              height="9px"
            /> */}
              <span className="country-name">Singapore</span>
              <div className={showAll ? 'arrow-up' : 'arrow-down'} />
            </button>
          </li>
        )}
        {!mobileView && (
          <li className="default">
            <button
              className="button-div"
              type="button"
              onClick={toggleSelector}
            >
              {/* <Image draggable={false}
              src="/images/au-flag.jpg"
              alt="Australia Flag"
              width="18px"
              height="9px"
            /> */}
              <span className="country-name">SG</span>
              <div className={showAll ? 'arrow-up' : 'arrow-down'} />
            </button>
          </li>
        )}
        {!mobileView && (
          <li className="child">
            <button
              className="button-div"
              type="button"
              onClick={toggleSelector}
            >
              {/* <Image draggable={false}
              src="/images/malasiya-flag.png"
              alt="Malasiya Flag"
              width="18px"
              height="9px"
            /> */}
              {isShowFullName ? (
                <span className="country-name">SINGAPORE</span>
              ) : (
                <span className="country-name">SG</span>
              )}
            </button>
          </li>
        )}
        <li className="child">
          <button
            className="button-div"
            type="button"
            onClick={handleAustralia}
          >
            {/* <Image draggable={false}
              src="/images/singapore-flag.png"
              alt="Singapore Flag"
              width="18px"
              height="9px"
            /> */}
            {isShowFullName ? (
              <span className="country-name">AUSTRALIA</span>
            ) : (
              <span className="country-name">AU</span>
            )}
          </button>
        </li>
        <li className="child">
          <button className="button-div" type="button" onClick={handleMalasia}>
            {/* <Image draggable={false}
              src="/images/malasiya-flag.png"
              alt="Malasiya Flag"
              width="18px"
              height="9px"
            /> */}
            {isShowFullName ? (
              <span className="country-name">MALAYSIA</span>
            ) : (
              <span className="country-name">MY</span>
            )}
          </button>
        </li>
        
      </ul>
      <style jsx>{styles}</style>
      <style jsx>
        {`
          ul {
            background-color: ${backgroundColor};
          }
        `}
      </style>
    </div>
  );
};

CountrySelector.propTypes = {
  backgroundColor: PropTypes.string,
};

CountrySelector.defaultProps = {
  backgroundColor: '#141414',
};

export default CountrySelector;
