import SidePanel from 'components/SidePanel';
import SidePanelCreateProfileForm from 'components/SidePanel/SidePanelCreateProfileForm';
import SidePanelForgotPasswordForm from 'components/SidePanel/SidePanelForgotPasswordForm';
import SidePanelLoginForm from 'components/SidePanel/SidePanelLoginForm';
import SidePanelYourProfile from 'components/SidePanel/SidePanelYourProfile';
import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  CUSTOMER_CREATE_MUTATTION,
  customerCreateVars,
  CUSTOMER_LOGIN_MUTATION,
  customerLoginVars,
} from 'helpers/customer';
import { StorefrontContext } from 'providers/storefront';
import { setCustomerToken as setCustomerTokenCookie, signOut } from 'helpers/cookies';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';

function ProfileButton({ isLoggedIn, setIsLoggedIn }) {
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { setProfileTabIndex } = useContext(StorefrontContext)

  const router = useRouter()

  useEffect(() => {
    if (isLoggedIn) setCurrentIndex(3)
  }, [isLoggedIn])

  const onHandleLogout = () => {
    signOut()
    setIsLoggedIn(false)
    setCurrentIndex(0)
    //bad and temporary solution
    window.location.reload()
  }

  const gotoOrders = () => {
    setProfileTabIndex(0)
    router.push('/me')
    setIsProfileModalOpen(false)
  }

  const gotoProfile = () => {
    setProfileTabIndex(1)
    router.push('/me')
    setIsProfileModalOpen(false)
  }


  const openChat = () => {
    console.log(window.$zopim.livechat)
    let devices = window.navigator.userAgent.toLowerCase()
    if (devices.includes('android') || devices.includes('mobile')) {
      window.$zopim.livechat.window.show()
    }
    else window.$zopim.livechat.window.toggle()
    // window.FrontChat('show');
  }

  const states = [
    {
      title: 'log in',
      content: (
        <SidePanelLoginForm
          setIsLoggedIn={setIsLoggedIn}
          onHandleForgotPassword={() => setCurrentIndex(1)}
          onHandleCreateProfile={() => setCurrentIndex(2)}
          onHandleLogin={() => setCurrentIndex(3)}
        />
      ),
    },
    {
      title: 'Forgot password',
      content: (
        <SidePanelForgotPasswordForm
          onHandleGoBack={() => setCurrentIndex(0)}
        />
      ),
    },
    {
      title: 'Create Profile',
      content: (
        <SidePanelCreateProfileForm onCreateProfile={() => setCurrentIndex(3)} onHandleLogin={() => setCurrentIndex(0)} />
      ),
    },
    {
      title: 'Your Profile',
      content: (
        <SidePanelYourProfile
          onHandleLogout={() => onHandleLogout()}
          gotoOrders={gotoOrders}
          gotoProfile={gotoProfile}
          openChat={openChat} />
      ),
    },
  ];
  return (
    <>
      <button
        className="profile_icon"
        onClick={() => setIsProfileModalOpen((prev) => !prev)}
      >
        <img draggable="false"
          src={
            isLoggedIn
              ? '/icons/icon-profile-logged-in.svg'
              : '/icons/icon-profile.svg'
          }
          alt="cart icon"
          width={24}
          height={24}
        />
      </button>

      <SidePanel
        title={states[currentIndex].title}
        isOpen={isProfileModalOpen}
        onClose={() => setIsProfileModalOpen(false)}
      >
        {states[currentIndex].content}
      </SidePanel>

      <style jsx>
        {`
          .profile_icon {
            background: transparent;
            border: none;
            cursor: pointer;
            opacity: 0.75;
          }
          .profile_icon:hover {
            opacity: 1;
          }
        `}
      </style>
    </>
  );
}

export default ProfileButton;
