import React from 'react';
import Script from 'next/script';

export default (
  <Script
    src="https://app.viralsweep.com/assets/js/action.js"
    id="viralsweep"
    strategy='lazyOnload'
  />
);
