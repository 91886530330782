import SidePanel from 'components/SidePanel';
import React, { useState } from 'react';
import { kRegions } from 'utils/constants';
import Image from 'next/image';

function RegionSwitchButton() {
  const [isRegionModalOpen, setIsRegionModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  return (
    <>
      <button
        className="button"
        onClick={() => setIsRegionModalOpen((prev) => !prev)}
      >
        <span>MY</span>
        <img draggable="false" src="/icons/icon-carat-down.svg" className="icon-carat" alt='carat_down'/>
      </button>

      <SidePanel
        title={'SELECT YOUR COUNTRY'}
        isOpen={isRegionModalOpen}
        // bannerImage="/images/thumbnail-building.png"
        // isHeaderCloseOnMobile
        onClose={() => setIsRegionModalOpen(false)}
      >
        {kRegions.map((region, idx) => (
          <RegionButton
            region={region}
            key={idx}
            index={idx}
            currentIndex={currentIndex}
            onChange={(val) => setCurrentIndex(val)}
          />
        ))}

        {currentIndex === 0 ? (
          <div className="store_container current_site">
            YOU’RE IN THE MALAYSIA STORE
          </div>
        ) : (
          <a
            href={kRegions[currentIndex].link || '#'}
            className="store_container go_to_link"
          >
            Go to the {kRegions[currentIndex].country} Site
          </a>
        )}
      </SidePanel>
      <style jsx>{`
        .store_container {
          font-family: 'Roboto-Medium';
          font-size: 13px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: center;
          margin-top: 16px;
          text-transform: uppercase;
          border-radius: 4px;
          padding: 12px;
          text-align: center;
        }
        .button {
          font-family: 'Roboto-Medium';
          font-size: 13px;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: 0em;
          text-align: left;
          display: flex;
          align-items: center;
          color: #909090;
          background-color: transparent;
          border: none;
        }
        .current_site {
          background: #242424;
        }
        .go_to_link {
          background-color: #950810;
          cursor: pointer;
        }
        .icon-carat {
          margin-left: 5px;
        }
      `}</style>
    </>
  );
}

function RegionButton({ region, index, currentIndex, onChange }) {
  return (
    <>
      <button key={index} className="side_item" onClick={() => onChange(index)}>
        <div
          className={`side_item_back000ground ${
            index === currentIndex ? 'side_item_a0ctive' : ''
          }`}
        ></div>
        <div className="side_item_content">
          <Image draggable={false} src={region.iconSrc} alt="country_icon" width={26} height={16}/>
          <span>{region.country}</span>
        </div>
      </button>
      <style jsx>{`
        .side_item_background {
          overflow: hidden;
          width: 100%;
          height: 40px;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1;
        }
        .side_item {
          border-radius: 4px;
          height: 40px;
          position: relative;
          background: none;
          border: none;
        }
        .side_item:hover {
          background-image: linear-gradient(#242424, #242424);
          cursor: pointer;
        }
        .side_item_active {
          background-image: linear-gradient(#fd0300, #950810);
          border-radius: 4px;
        }
        .side_item_content {
          padding: 12px;
          display: flex;
          align-items: center;
          gap: 16px;
          text-transform: uppercase;
          background-color: #000000;
          border-radius: 4px;
          height: 38px;
          position: absolute;
          top: 1px;
          left: 1px;
          right: 1px;
          bottom: 1px;
          z-index: 1;
          font-family: 'Roboto-Medium';
          font-size: 13px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          text-align: left;
          color: #e4e4e4;
        }
      `}</style>
    </>
  );
}

export default RegionSwitchButton;
