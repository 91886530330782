import gql from 'graphql-tag';
import { CheckoutFragment } from './graphql/checkout';

export const CUSTOMER_FRAGEMENT = gql`
  ${CheckoutFragment}
  fragment customer on Customer {
    id
    email
    firstName
    lastName
    phone
    defaultAddress {
      id
      address1
      city
      province
      zip
      country
    }
    lastIncompleteCheckout {
      ...CheckoutFragment
    }
    orders(first: 20, reverse: true) {
      edges {
        node {
          id
          orderNumber
          currencyCode
          statusUrl
          totalPriceV2 {
            amount
            currencyCode
          }
          lineItems(first: 10) {
            edges {
              node {
                title
                quantity
                variant {
                  product {
                    handle
                  }
                  selectedOptions {
                    name
                    value
                  }
                  image {
                    altText
                    originalSrc
                    transformedSrc(maxWidth: 700)
                  }
                  priceV2 {
                    amount
                    currencyCode
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const CUSTOMER_LOGIN_MUTATION = gql`
  mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
    customerAccessTokenCreate(input: $input) {
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;

export const customerLoginVars = ({ email, password }) => ({
  input: {
    email,
    password,
  },
});

export const CUSTOMER_CREATE_MUTATTION = gql`
  mutation customerCreate($input: CustomerCreateInput!) {
    customerCreate(input: $input) {
      customer {
        id
        email
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;

export const customerCreateVars = ({
  firstName,
  lastName,
  email,
  password,
}) => ({
  input: {
    firstName,
    lastName,
    email,
    password,
  },
});

export const CUSTOMER_QUERY = gql`
  ${CUSTOMER_FRAGEMENT}
  query customer($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      ...customer
    }
  }
`;

export const CUSTOMER_UPDATE_MUTATION = gql`
  mutation customerUpdate(
    $customerAccessToken: String!
    $customer: CustomerUpdateInput!
  ) {
    customerUpdate(
      customerAccessToken: $customerAccessToken
      customer: $customer
    ) {
      customer {
        id
        email
        firstName
        lastName
        phone
      }
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;

export const customerUpdateVars = (
  accessToken,
  { email, firstName, lastName, phone },
) => ({
  customerAccessToken: accessToken,
  customer: {
    email,
    firstName,
    lastName,
    phone,
  },
});

export const CUSTOMER_ADDRESS_UPDATE_MUTATION = gql`
  mutation customerAddressUpdate(
    $customerAccessToken: String!
    $id: ID!
    $address: MailingAddressInput!
  ) {
    customerAddressUpdate(
      customerAccessToken: $customerAccessToken
      id: $id
      address: $address
    ) {
      customerAddress {
        id
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;

export const customerAddressUpdateVars = (
  accessToken,
  id,
  { address1, city, province, zip, country },
) => ({
  customerAccessToken: accessToken,
  id,
  address: {
    address1,
    city,
    province,
    zip,
    country,
  },
});

export const CUSTOMER_ADDRESS_CREATE_MUTATION = gql`
  mutation customerAddressCreate(
    $customerAccessToken: String!
    $address: MailingAddressInput!
  ) {
    customerAddressCreate(
      customerAccessToken: $customerAccessToken
      address: $address
    ) {
      customerAddress {
        id
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;

export const customerAddressCreateVars = (
  accessToken,
  { address1, city, province, zip, country },
) => ({
  customerAccessToken: accessToken,
  address: {
    address1,
    city,
    province,
    zip,
    country,
  },
});

export const CUSTOMER_DEFAULT_ADDRESS_UPDATE_MUTATION = gql`
  mutation customerDefaultAddressUpdate(
    $customerAccessToken: String!
    $addressId: ID!
  ) {
    customerDefaultAddressUpdate(
      customerAccessToken: $customerAccessToken
      addressId: $addressId
    ) {
      customer {
        id
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;

export const customerDefaultAddressUpdateVars = (accessToken, addressId) => ({
  customerAccessToken: accessToken,
  addressId,
});

export default CUSTOMER_LOGIN_MUTATION;
