import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import Logo from 'components/Logo';
import Image from 'next/image';
import { StorefrontContext } from 'providers/storefront';
import { getCheckoutId } from 'helpers/cookies';
import Link from 'next/link';
import CartCounter from '../Header/Navigation/CartCounter';
import styles from './styles';
import Countdown from 'react-countdown';
import ProfileButton from 'components/NavigationButtonIcons/ProfileButton';
import BannerCountdownTimer from '../Header/BannerCountdownTimer';

const MobileHeader = ({ header, toggleMenu }) => {
  const [checkoutId, setCheckoutId] = useState(getCheckoutId());
  const {
    setIsCartOpen,
    checkout,
    setIsAnnouncementBarOpen,
    isAnnouncementBarOpen,
    isMenuOpen,
    isLoggedIn,
    setIsLoggedIn,
  } = useContext(StorefrontContext);
  useEffect(() => {
    if (checkout?.id) setCheckoutId(checkout.id);
  }, [checkout]);

  const topBanner = header && header?.body?.filter(item => item?.slice_type === 'shipping_banner');
  const topBannerContents = topBanner && topBanner[0]?.primary?.banner_contents;
  const topBannerTimer =
    header &&
    header?.body?.filter((item) => item?.slice_type === 'shipping_banner');
  const topBannerTimerContents = topBannerTimer && topBannerTimer[0]?.primary?.countdown_end;

  const renderer = ({ days = null, hours = null, minutes = null, seconds = null, completed = null }) => {
    if (completed) {
      return <></>;
    } else {
      return (
        <span>
          {' '}
          | Ends in {days}d {hours}h {minutes}m {seconds}s
        </span>
      );
    }
  };

  const handleCloseBanner = () => {
    setIsAnnouncementBarOpen(false);
  };
  const handleCartOpen = () => {
    setIsCartOpen(true);
  };

  const handleChatToggle = () => {
    console.log(window.$zopim.livechat)
    let devices = window.navigator.userAgent.toLowerCase()
    if (devices.includes('android') || devices.includes('mobile')) {
      window.$zopim.livechat.window.show()
    }
    else window.$zopim.livechat.window.toggle()
    // window.FrontChat('show')
  }
  return (
    <>
      <div className="hamburger_menu">
        {isAnnouncementBarOpen && (
          <div className="header_menu_banner wrapper">
            <div className="shipping-banner">
              {topBannerTimerContents ? (
               <div className="banner-with-timer">
                  <span>{topBannerContents}</span>
                  <BannerCountdownTimer date={topBannerTimerContents} />
                  {/* <Countdown
                    renderer={renderer}
                    date={new Date(topBannerTimerContents)}
                  /> */}
              </div>
              ) : (
                topBannerContents
              )}
              <div
                className="close_banner"
                aria-hidden="false"
                onClick={handleCloseBanner}
              >
                <Image draggable={false}
                  src="/icons/icon-close.svg"
                  alt="close icon"
                  width="16"
                  height="16"
                />
              </div>
            </div>
          </div>
        )}
        <div
          className={`menu-header wrapper ${isMenuOpen ? `menu_open` : `menu_close`
            }`}
        >
          <div className="menu_icon">
            <div className="menu_icon">
              <Logo width={148} height={32} />
            </div>
          </div>

          <div className="menu_icon">
            <div className="right_icons">
              <div
                type="button"
                className="icons icons_container chat_icon"
                aria-hidden="false"
                onClick={handleChatToggle}
              >
                <Image draggable={false}
                  src={'/icons/icon-chat.svg'}
                  alt={'icon-chat'}
                  width={18}
                  height={18}
                />
                <span className="chat_badge" />
                <span className="chat_badge ping" />
              </div>
              {/* <ProfileButton isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} /> */}

              <div
                type="button"
                className="icons icons_container"
                onClick={handleCartOpen}
                aria-hidden="false"
              >
                <Image draggable={false}
                  src="/icons/icon-cart.svg"
                  alt="cart icon"
                  layout="fixed"
                  width={24}
                  height={24}
                />
                <span>
                  {checkoutId && <CartCounter checkoutId={checkoutId} />}
                </span>
              </div>

              <div style={{"display":"flex","justifyContent":"center","alignItems":"center"}}>
                {isMenuOpen ? (
                  <Image draggable={false}
                    src="/icons/icon-close.svg"
                    alt="close icon"
                    width={24}
                    height={24}
                    onClick={toggleMenu}
                    layout="fixed"
                  />
                ) : (
                  <Image draggable={false}
                    src="/icons/icon-hamburger.svg"
                    alt="close icon"
                    width={24}
                    height={24}
                    onClick={toggleMenu}
                    layout="fixed"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <style jsx>{styles}</style>
    </>
  );
};

MobileHeader.propTypes = {
  toggleMenu: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  header: PropTypes.any,
};

MobileHeader.defaultProps = {
  toggleMenu: null,
  header: null,
};

export default MobileHeader;
