import React from 'react';

function SidePanelCheckboxInput({ value, onChange, label }) {
  console.log({ value });
  return (
    <>
      <div className="checkbox_group">
        <div className="checkbox" onClick={() => onChange(!value)}>
          {value ? (
            <img draggable="false"
              src="/icons/icon-check-red.svg"
              alt="checkbox-checked"
              className="rounded"
            />
          ) : (
            <div className="box_red" />
          )}
        </div>
        <span className="checkbox_label">{label}</span>
      </div>

      <style jsx>{`
        .checkbox_label {
          font-family: 'Roboto-Medium';
          font-size: 13px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          text-align: left;
          color: #e4e4e4;
        }
        .checkbox_group {
          display: flex;
          gap: 8px;
          align-items: center;
        }
        .rounded {
          border-radius: 2px;
        }
        .box_red {
          height: 16px;
          width: 16px;
          border-radius: 2px;
          background: #950810;
        }
        .checkbox {
          cursor: pointer;
          background: #fff;
          height: 16px;
          width: 16px;
          border-radius: 5px;
          user-select: none;
        }
      `}</style>
    </>
  );
}

export default SidePanelCheckboxInput;
