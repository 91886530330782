import Cookies from 'js-cookie';
import nextCookies from 'next-cookies';

export const getCheckoutId = () => Cookies.get('checkoutIdNew');

export const setCheckoutId = (checkoutId) => {
  Cookies.set('checkoutIdNew', checkoutId, {
    expires: 6,
  });
};

export const removeCheckoutId = () => Cookies.remove('checkoutIdNew');

export const getPasswordProtect = (ctx) => {
  if (ctx && ctx.req) {
    const { PasswordProtect } = nextCookies(ctx);
    return PasswordProtect;
  }
  return Cookies.get('PasswordProtect');
};

export const setCustomerToken = (customerAccessToken) => {
  Cookies.set('customerToken', JSON.stringify(customerAccessToken), {
    expires: 7,
  });
};
export const signOut = () => {
  Cookies.remove('customerToken');
};
