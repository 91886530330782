/* eslint-disable react/prop-types */
import React, { memo } from 'react';
import Router from 'next/router';
import slugify from 'slugify';
import FourthMobile from '../FourthMobile';

const TertiaryMobile = ({ items, onClose }) => {
  Router.onRouteChangeStart = () => {
    onClose();
  };

  if (!items.length) {
    return null;
  }
  return (
    <>
      <div className="category_navigation_container">
        <div>
          {items && (
            <FourthMobile
              items={items}
              onClose={onClose}
              showRTSBannerMobile={showRTSBannerMobile}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default memo(TertiaryMobile);
