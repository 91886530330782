import React, { useState, useEffect, createContext } from 'react';
import Cookies from 'js-cookie';
const Context = createContext(null);

const StorefrontProvider = (props) => {
  const { globalProps, children } = props;
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [checkout, setCheckout] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [profileTabIndex, setProfileTabIndex] = useState(0)
  const [isAnnouncementBarOpen, setIsAnnouncementBarOpen] = useState(true);
  const [customDesignUrl, setCustomDesignUrl] = useState('');

  useEffect(() => {
    if (Cookies.get('customerToken')) {
      setIsLoggedIn(true);
    }
  });

  useEffect(() => {
    // if (window && window.$zopim.livechat) {
      //turn off initially
      // window.$zopim.livechat.hideAll()
      //client requested that they want it visible initially
    // }
  }, [])


  return (
    <Context.Provider
      value={{
        globalProps,
        isCartOpen,
        isAnnouncementBarOpen,
        profileTabIndex,
        isMenuOpen,
        isLoggedIn,
        checkout,
        customDesignUrl,
        setIsLoggedIn,
        setProfileTabIndex,
        setIsMenuOpen,
        setCheckout,
        setIsAnnouncementBarOpen,
        setIsCartOpen,
        setCustomDesignUrl
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default StorefrontProvider;
export { Context as StorefrontContext };
