import React from 'react';

function ChatPingingIconButton({ onClick }) {
  return (
    <>
      <button className="icon_button" onClick={onClick}>
        <img draggable="false"
          src={'/icons/icon-chat.svg'}
          alt={'icon-chat'}
          width={18}
          height={18}
        />
        <span className="chat_badge" />
        <span className="chat_badge ping" />
      </button>
      <style>
        {`
        .chat_icon {
            position: relative;
            border: none;
            background: none;
            opacity: 0.75;
          }
          .chat_icon:hover {
            opacity: 1;
          }
          .ping {
            animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
          }
          @keyframes ping {
            75%,
            100% {
              transform: scale(2);
              opacity: 0;
            }
          }
          .chat_badge {
            background: #7ed321;
            height: 8px;
            width: 8px;
            border-radius: 50%;
            position: absolute;
            top: 0px;
            right: 2px;
          }
          .icon_button {
            background: transparent;
            border: none;
            cursor: pointer;
            opacity: 0.75;
            position: relative;
          }
          .icon_button:hover {
            opacity: 1;
          }
        `}
      </style>
    </>
  );
}

export default ChatPingingIconButton;
