import css from 'styled-jsx/css';

export default css`
  .category_navigation__secondary {
    display: flex;
    flex-direction: column;
    background: #141414;
    box-sizing: border-box;
  }
  .category_navigation__secondary > div {
    text-align: left;
    outline: none;
  }
  .category_navigation_container {
    padding: 0;
    top: 100%;
    left: 0px;
    right: 0px;
    display: none;
    position: absolute;
    background: #242424;
    z-index: 3;
    color: white;
  }
  .category_navigation_secondary_column {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1px;
  }
  .second-level:first-child .second-level-title {
    border-radius: 4px 0 0 4px;
  }
  .second-level:last-child .second-level-title {
    border-radius: 0px 4px 4px 0px;
  }
  .second-level:only-child .second-level-title {
    border-radius: 4px;
  }
  .category_navigation_secondary_column .menu_group {
    margin-top: 24px;
    margin-bottom: 8px;
    display: flex;
  }
  .ksps {
    font-size: 13px;
    line-height: 24px;
    color: #949494;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 68px;
    opacity: 75%;
  }
  .ksps .item_group {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .quiz-wrapper {
    width: 100%;
    background: #242424;
  }
  .quiz {
    margin: 0 auto;
    max-width: 1440px;
    width: 100%;
    height: 48px;
    background: #242424;
    display: flex;
    align-items: center;
    justify-content: start;
  }
  .quiz .quiz_title {
    margin-left: 24px;
    color: #e4e4e4;
    font-size: 13px;
    line-height: 20px;
    font-weight: 400px;
  }
  .quiz_button {
    cursor: pointer;
    font-size: 13px;
    line-height: 24px;
    margin-left: 32px;
    padding: 4px 16px;
    background: #950810;
    border-radius: 4px;
  }
  .category_navigation_container.active {
    display: block;
  }

  .secondary_title {
    color: #e4e4e4;
    font-family: 'Roboto-Medium';
    font-size: 16px;
    max-width: 312px;
    margin-bottom: 24px;
    visibility: hidden;
    display: flex;
  }

  .other_contents {
    flex: 2;
    display: flex;
    justify-content: flex-end;
    padding-top: 32px;
    padding-left: 32px;
  }

  .category_navigation__secondary .category_navigation__title {
    padding: 12px 32px;
    flex: 1;
    display: flex;
    align-items: center;
  }
  .item-list {
    width: 100%;
    padding: 16px 48px 40px 48px;
    display: flex;
    flex-flow: row wrap;
  }
  .item-list-item {
    flex: 0 0 25%;
  }
  .justify-content-center {
    justify-content: center;
  }
  /* .parent-gaming {
    display: block;
  } */

  .third-level-container {
    flex: 1;
    padding-top: 32px;
    min-height: 500px;
    padding-left: 32px;
  }

  .category_navigation__secondary .third-level-container:first-child {
    max-width: 320px;
  }

  .third-level-container:first-child {
    padding-left: 0px;
  }
  .third-level-container:first-child .secondary_title {
    visibility: visible;
  }
  .second-level-title {
    background: #242424;
    padding: 4px 16px;
    font-size: 13px;
    line-height: 24px;
    color: #e4e4e4;
    cursor: pointer;
    opacity: 75%;
  }
  .second-level-title :hover {
    opacity: 100%;
  }

  .second-level-title-2 {
    color: #e4e4e4;
    background: #950810;
    opacity: 100%;
  }

  .third-level-title {
    font-size: 25px;
    line-height: 32px;
    color: #e4e4e4;
    padding-bottom: 16px;
  }
  .hidden {
    display: none;
  }

  .arrow-right {
    margin-left: 5px;
    margin-top: 2px;
  }
  .find-your-setup {
    height: 56px;
    width: 100%;
    background-color: #000000;
  }

  .cta_container {
    padding: 24px 0;
    background-color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #242424;
  }
  .cta_description,
  .cta_link {
    color: #e4e4e4;
    font-family: 'Roboto-Medium';
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
  }
  .cta_link {
    display: flex;
    align-items: center;
    color: #fd0300;
  }
  .arrow_right {
    display: flex;
    align-items: center;
    padding-top: 3px;
    padding-left: 5px;
  }
`;
