import React from 'react';
import ContentLoader from 'react-content-loader';

const SideCartLoader = () => (
  <ContentLoader
    height={400}
    width={320}
    speed={2}
    backgroundColor={'#333'}
    foregroundColor={'#999'}
  >
    <rect x="20" y="8" rx="0" ry="0" width="100" height="100" />
    <rect x="20" y="120" rx="0" ry="0" width="100" height="10" />
    <rect x="170" y="8" rx="0" ry="0" width="300" height="15" />
    <rect x="170" y="30" rx="0" ry="0" width="300" height="15" />
    <rect x="170" y="52" rx="0" ry="0" width="100" height="15" />
    <rect x="20" y="154" rx="0" ry="0" width="320" height="49" />
  </ContentLoader>
);

export default SideCartLoader;
