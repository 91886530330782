export const kSocials = [
  {
    href: 'https://www.facebook.com/aftershockpcau',
    iconSrc: '/icons/facebook-16px.svg',
    alt: 'facebook icon',
  },
  {
    href: 'https://www.instagram.com/aftershockpcau/',
    iconSrc: '/icons/instagram-16px.svg',
    alt: 'instagram icon',
  },
  {
    href: 'https://twitter.com/aftershockau',
    iconSrc: '/icons/twitter-16px.svg',
    alt: 'twiter icon',
  },
  {
    href: 'https://www.youtube.com/user/aftershocknotebooks',
    iconSrc: '/icons/youtube-16px.svg',
    alt: 'youtube icon',
  },
];

export const kRegions = [
  {
    code: 'MY',
    country: 'Malaysia',
    iconSrc: '/icons/flag-malaysia.svg',
    link: '/',
  },
  {
    code: 'SG',
    country: 'Singapore',
    iconSrc: '/icons/flag-singapore.svg',
    link: 'https://www.aftershockpc.com/',
  },
  {
    code: 'AU',
    country: 'Australia',
    iconSrc: '/icons/flag-australia.svg',
    link: 'https://www.aftershockpc.com.au/',
  },
];
export const kFeatureList = [
  {
    groupType: 'graphics_card',
    image: '/icons/icon-gpu.svg',
  },
  {
    groupType: 'cpu',
    image: '/icons/icon-cpu.svg',
  },
  {
    groupType: 'ram',
    image: '/icons/icon-ram.svg',
  },
  {
    groupType: 'primary_ssd',
    image: '/icons/icon-ssd.svg',
  },
  {
    groupType: 'cpu_cooling_system',
    image: '/icons/icon-cooling.svg',
  },
  {
    groupType: 'cpu_cooling system',
    image: '/icons/icon-cooling.svg',
  },
  {
    groupType: 'motherboard',
    image: '/icons/icon-motherboard.svg',
  },
  {
    groupType: 'display',
    image: '/icons/icon-display.webp',
  },

  {
    groupType: 'airflow',
    image: '/icons/unique-feature-types/airflow.png',
  },
  {
    groupType: 'chassis',
    image: '/icons/unique-feature-types/chassis.png',
  },
  {
    groupType: 'color',
    image: '/icons/unique-feature-types/color.png',
  },
  {
    groupType: 'dimension',
    image: '/icons/unique-feature-types/dimension.png',
  },
  {
    groupType: 'glass_design',
    image: '/icons/unique-feature-types/glass_design.png',
  },
  {
    groupType: 'power',
    image: '/icons/unique-feature-types/power.png',
  },
  {
    groupType: 'screen',
    image: '/icons/unique-feature-types/screen.png',
  },
  {
    groupType: 'weight',
    image: '/icons/unique-feature-types/weight.png',
  },
  {
    groupType: 'special_usp',
    image: '/icons/unique-feature-types/special_usp.png',
  },
];
