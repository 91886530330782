/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import Link from 'next/link';
import styles from './styles';

const LineItemProduct = ({
  item,
  removeLineItemInCart,
  updateLineItemInCart,
  delayed,
}) => {
  const [showCustomBuild, setShowCustomBuild] = useState(false);

  const { id, quantity, variant, title, customAttributes } = item.node;
  const productType = variant?.product.productType;
  const productTags = variant?.product.tags;
  const customAttributesData = customAttributes?.filter((item) =>
    item.key.startsWith('_lineitem')
  );
  const customComponentsQuantity = customAttributesData?.length;

  const customDesignUrlData = customAttributes?.filter((item) =>
    item.key.startsWith('Custom Design')
  );

  const customDesignUrl =
    customDesignUrlData.length > 0 && customDesignUrlData[0].value;

  const decrementQuantity = (lineItemId) => {
    const updatedQuantity = quantity - 1;
    updateLineItemInCart(lineItemId, updatedQuantity);
  };

  const incrementQuantity = (lineItemId) => {
    const updatedQuantity = quantity + 1;
    updateLineItemInCart(lineItemId, updatedQuantity);
  };
  const decrementItem = () => {
    decrementQuantity(id);
  };
  const increaseItem = () => {
    incrementQuantity(id);
  };
  const removeItem = () => {
    removeLineItemInCart(id);
  };

  const handleOpenCustomBuild = () => {
    setShowCustomBuild(true);
  };
  const handleCloseCustomBuild = () => {
    setShowCustomBuild(false);
  };
  const handleToggleCustomBuild = () => {
    setShowCustomBuild(!showCustomBuild);
  };

  const customProductLink = item?.node?.customAttributes?.find(
    (att) => att.key === 'link'
  )?.value;

  return (
    <div key={id}>
      <li
        className="Line-item"
        style={customDesignUrl ? { paddingBottom: 0, borderBottom: 0 } : {}}
      >
        <div className="Line-item__img">
          {variant.image ? (
            <Image draggable={false}
              src={variant.image.src}
              alt="product image"
              width={110}
              height={110}
            />
          ) : customDesignUrl ? (
            <img draggable="false"
              src={customDesignUrl}
              alt="product image"
              style={{
                width: '85.68px',
                height: '85.68px',
                objectFit: 'cover',
              }}
            />
          ) : null}
          {delayed && (
            <div className="delay_icon">
              <Image draggable={false}
                src="/icons/icon-exclamation-blue.svg"
                alt="Error"
                width={16}
                height={16}
              />
            </div>
          )}
        </div>
        <div className="Line-item__content">
          <div className="Line-item__content-row">
            <div className="Line-item__variant-title" />
            {productType === 'Custom' && !customProductLink && (
              <span>{title}</span>
            )}
            {productType === 'Custom' && customProductLink ? (
              <Link href={customProductLink || '#'}>
                <a className="Line-item__title" href={customProductLink || '#'}>
                  {title}
                </a>
              </Link>
            ) : productType == 'Ready to Ship' ? (
              <Link href={`/products/${variant.product.handle}`}>
                <a
                  className="Line-item__title"
                  href={`/products/${variant.product.handle}`}
                >
                  {title}
                </a>
              </Link>
            ) : (
              <Link href={`#`} className="inactiveLink">
                <a
                  className="Line-item__title inactiveLink"
                  // href={`/products/${variant.product.handle}`}
                  href={`#`}
                >
                  {title}
                </a>
              </Link>
            )}
          </div>

          <div className="line_item_price">
            {variant?.price && (
              <span>
                RM
                {variant?.price
                  .toLocaleString()
                  .replace(/(\d{1,2})(?=(\d{3})+\.)/g, '$1,')}
              </span>
            )}
            {variant?.compareAtPrice && (
              <div className="sale_price">
                <span className="saved_money">
                  You saved RM{variant.compareAtPrice - variant.price}
                </span>
                &nbsp;
                <span className="compare_at_price">
                  <del>
                    RM
                    {variant.compareAtPrice
                      .toLocaleString()
                      .replace(/(\d{1,2})(?=(\d{3})+\.)/g, '$1,')}
                  </del>
                </span>
              </div>
            )}
            {variant?.compareAtPrice && (
              <div className="sale_price sale_price_mobile">
                <span className="compare_at_price">
                  <del>RM {variant.compareAtPrice}</del>
                </span>
                &nbsp;
                <span className="saved_money">
                  You saved RM{variant.compareAtPrice - variant.price}
                </span>
              </div>
            )}
          </div>

          {/* {productType === 'Accessories' || productTags.includes('custom') ? ( */}
          <div className="Line-item__quantity-container">
            <button
              type="button"
              className="Line-item__quantity-update"
              onClick={decrementItem}
            >
              -
            </button>
            <span className="Line-item__quantity">{quantity}</span>
            <button
              type="button"
              className="Line-item__quantity-update"
              onClick={increaseItem}
            >
              +
            </button>
          </div>
          {/* ) : null} */}

          {productTags.includes('custom') && (
            <div className="custom-builds-components">
              <div className="components-title">
                <h4 onClick={handleToggleCustomBuild}>
                  Includes {customComponentsQuantity} custom components
                </h4>

                {showCustomBuild === true ? (
                  <button
                    type="button"
                    className="show-information"
                    onClick={handleCloseCustomBuild}
                  >
                    <Image draggable={false}
                      src="/icons/icon_minus.svg"
                      alt="show components icon"
                      width={10}
                      height={10}
                    />
                  </button>
                ) : (
                  <button
                    type="button"
                    className="show-information"
                    onClick={handleOpenCustomBuild}
                  >
                    <Image draggable={false}
                      src="/icons/icon_plus.svg"
                      alt="close components icon"
                      width={10}
                      height={10}
                    />
                  </button>
                )}
              </div>
              {showCustomBuild === true &&
                customAttributesData.length !== 0 &&
                customAttributesData.map((item) => {
                  const componentTitle = JSON.parse(item?.value)?.title;
                  return (
                    <div className="components-list">
                      <p className="title">{componentTitle}</p>
                    </div>
                  );
                })}
            </div>
          )}
        </div>

        <div onClick={removeItem} className="Line-item__remove">
          {/* <Image draggable={false}
            src="/icons/icon_trash.svg"
            alt="cart icon"
            className="cart_icon"
            width={15}
            height={15}
          /> */}
        </div>
      </li>
      {customDesignUrl && (
        <p className="Custom-url">
          link:{' '}
          <a
            target="_blank"
            href={customDesignUrl}
            style={{ color: '#ed000d' }}
          >
            {customDesignUrl}
          </a>
        </p>
      )}
      <style jsx>{styles}</style>
    </div>
  );
};

LineItemProduct.propTypes = {
  item: PropTypes.shape({
    __typename: PropTypes.string,
    node: {
      id: PropTypes.string,
      quantity: PropTypes.number,
      variant: PropTypes.object,
      title: PropTypes.string,
      customAttributes: PropTypes.array,
    },
  }).isRequired,
  removeLineItemInCart: PropTypes.func.isRequired,
  updateLineItemInCart: PropTypes.func.isRequired,
  customComponents: PropTypes.array,
  delayed: PropTypes.bool,
};

LineItemProduct.defaultProps = {
  customComponents: [],
  delayed: false,
};

export default LineItemProduct;
