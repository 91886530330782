import memoizee from 'memoizee';
const pluck = (data, path) => {
  if (!path) {
    return data;
  }
  try {
    const keys = path.split('.');
    for (let key of keys) {
      if (typeof data !== 'object' || typeof data[key] === 'undefined') {
        return null;
      }
      data = data[key];
    }

    return data;
  } catch (e) {
    return null;
  }
};

export default memoizee(pluck);
