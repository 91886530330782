import React from 'react';

function SidePanelTextField({ placeholder, value, onChange, type, field, ...props }) {
  // console.log(field)
  // console.log(props)

  return (
    <>
      <div className="input_group">
        <input
          className="input"
          type={type ?? 'text'}
          placeholder={placeholder}
          {...field}
        />
        <div className="input_error">
          {props.form && props.form.touched[field.name]
            ? props.form.errors[field.name]
            : ''}
        </div>
      </div>

      <style jsx>{`
        .input {
          color: rgba(228, 228, 228, 0.75);
          height: 48px;
          background: #000000;
          border-radius: 4px;
          width: 100%;
          border: 1px solid
            ${props.form &&
            props.form.touched[field.name] &&
            props.form.errors[field.name]
              ? '#950810'
              : '#242424'};
          padding: 14px 16px;
          font-family: 'Roboto-Medium';
          font-size: 13px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          text-align: left;
        }
        .input_error {
          margin-top: 5px;
          color: #950810;
          font-size: 14px;
        }
      `}</style>
    </>
  );
}

export default SidePanelTextField;