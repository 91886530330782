/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import pluck from 'utils/pluck';
import slugify from 'slugify';
import Secondary from '../Secondary';
import styles from './styles';

const Primary = ({ ksps, quiz, data, opacityRef }) => {
  const [mainMenuHoverIndex, setMainMenuHoverIndex] = useState(null);
  const [activeLinkIndex, setActiveLinkIndex] = useState(null);
  const [openNav, setOpenNav] = useState(false);
  const handleBodyOpacity = () => {
    opacityRef.current.style.opacity = '0.25';
    opacityRef.current.style.backgroundColor = '#000000';
  };
  const handleBodyOpacityLeave = () => {
    opacityRef.current.style.opacity = '1';
    opacityRef.current.style.backgroundColor = '#000';
  };
  return (
    <>
      <div className="primary_menu">
        {data.map((item, index) => {
          const primary = item.filter((n) => n.slice_type === '1st_level');
          const handleActiveMenu = (event = undefined) => {
            setMainMenuHoverIndex(index + 1);
            if (
              !(
                event.target.parentElement.parentElement.classList.contains(
                  'accessories',
                ) ||
                event.target.parentElement.classList.contains('accessories') ||
                event.target.classList.contains('accessories') ||
                event.target.parentElement.parentElement.classList.contains(
                  'ready-to-ship',
                ) ||
                event.target.parentElement.classList.contains(
                  'ready-to-ship',
                ) ||
                event.target.classList.contains('ready-to-ship')
              )
            )
              handleBodyOpacity();
          };
          const handleOut = () => {
            setMainMenuHoverIndex(null);
          };
          const handleMouseOver = () => {
            setActiveLinkIndex(activeLinkIndex);
            setOpenNav(true);
          };
          const handleClick = () => {
            setActiveLinkIndex(index);
            setOpenNav(!openNav);
          };
          const handleSecondaryClick = () => {
            setActiveLinkIndex(index);
          };
          if (!(primary.length > 0)) return null;
          const url = pluck(primary, '0.primary.link');
          const title = pluck(primary, '0.primary.title');
          const items = item.filter((n) => n.slice_type === '2nd_level');
          const key = `primary-${index}`;
          const menuType = pluck(primary, '0.primary.menu_type');
          const columns = pluck(primary, '0.items');
          const parent = pluck(primary, '0.primary');
          const classNames = `top-level  ${slugify(title).toLocaleLowerCase()}`;
          const rtsItems = item.filter((n) => n.slice_type === '3rd_level');
          return (
            <div
              tabIndex={10 + index}
              className={classNames}
              key={key}
              onMouseOver={(event) => handleActiveMenu(event)}
              onMouseOut={handleOut}
              onClick={handleActiveMenu}
              aria-hidden="false"
              onMouseLeave={handleBodyOpacityLeave}
            >
              {url && (
                <Link href={url || '#'}>
                  <a href={url || '#'}>
                    <div
                      onMouseOver={handleMouseOver}
                      onClick={handleClick}
                      aria-hidden="false"
                      className={`top-level-title ${
                        mainMenuHoverIndex === index + 1 ? 'top-level-2' : null
                      } ${
                        activeLinkIndex === index ? 'top-level-activated' : null
                      }`}
                    >
                      {title}
                    </div>
                  </a>
                </Link>
              )}

              {menuType === 'mega-menu' && (
                <div>
                  <Secondary
                    onClick={handleSecondaryClick}
                    openNav={openNav}
                    items={items.length > 0 ? items : rtsItems}
                    kspsItems={ksps}
                    quizItems={quiz}
                    columns={columns}
                    parent={parent}
                    mainMenuHoverIndex={mainMenuHoverIndex - 1}
                    mainMenuCurrentIndex={index}
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>
      <style jsx>{styles}</style>
    </>
  );
};

Primary.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array,
};

Primary.defaultProps = {
  data: [],
};

export default memo(Primary);
