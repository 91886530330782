import React from 'react';

const CartEmpty = () => (
  <>
    <p className="cart_empty">Your Cart is Empty</p>
    <style jsx>
      {`
        .cart_empty {
          color: #e4e4e4;
          font-family: 'Roboto-Medium';
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 24px;
        }
      `}
    </style>
  </>
);

export default CartEmpty;
