import React, { useState } from 'react';
import CUSTOMER_RECOVER_MUTATION from './graph';
import { useMutation } from '@apollo/client';

function SidePanelForgotPasswordForm({ onHandleGoBack }) {
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [recoverCustomer, { error, data }] = useMutation(
    CUSTOMER_RECOVER_MUTATION,
  );

  const onSubmit = (e) => {
    console.log('TEST submit')
    e.preventDefault();
    setSuccess(false);
    setErrorMessage(false);

    recoverCustomer({
      variables: { email: email },
      update(proxy, { data: { customerRecover } }) {
        console.log('TEST here', customerRecover)
        if (customerRecover.customerUserErrors.length === 0) {
          setSuccess(true)
          setEmail('')
        } else {
          setErrorMessage(customerRecover.customerUserErrors[0].message)
        }
      },
      onerror(error) {
        console.log('TEST', error)
        setErrorMessage(true)
      },
    }).catch((error) => {
      setErrorMessage(true)
    });
  }

  return (
    <>
      <div className="main">
        <form onSubmit={(e) => onSubmit(e)}>
          <div className="input_group">
            <input
              className="input"
              type="email"
              placeholder={'Email'}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          {success && (
            <p className="success">
              We have sent you an email with a link to update your password.
            </p>
          )}
          {errorMessage && (
            <p className="error">
              {errorMessage == true
                ? 'Resetting password limit exceeded. Please try again later.'
                : errorMessage}
            </p>
          )}
          <div className="button_container">
            <button className="button" type="submit">
              {'Recover Password'}
            </button>
          </div>
        </form>
        <button className="go_back_button" onClick={onHandleGoBack}>
          Go Back
        </button>
      </div>

      <style jsx>{`
        .input {
          color: rgba(228, 228, 228, 0.75);
          height: 48px;
          background: #000000;
          border-radius: 4px;
          width: 100%;
          border: 2px solid ${error ? '#950810' : '#242424'};
          padding: 14px 16px;
          font-family: 'Roboto-Medium';
          font-size: 13px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          text-align: left;
        }
        .main {
          display: flex;
          flex-direction: column;
          height: 100vh;
        }
        .button_container {
          margin-top: 24px;
        }
        .go_back_button {
          font-family: 'Roboto-Medium';
          font-size: 13px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          text-decoration-line: underline;
          background: transparent;
          border: none;
          color: #e4e4e4;
          text-transform: none;
          margin: 0 auto;
          margin-top: 12px;
        }
        .success {
          font-size: 11px;
          color: #3cff3c;
        }
        .error {
          font-size: 11px;
          color: red;
        }
        .button {
          width: 320px;
          height: 48px;
          font-family: 'Roboto-Medium';
          font-size: 13px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: center;
          background: #950810;
          border-radius: 4px;
          border: none;
          color: #e4e4e4;
          cursor: pointe;
        }
        @media (max-width: 640px) {
          .button {
            width: 100%;
          }
        }
      `}</style>
    </>
  );
}

export default SidePanelForgotPasswordForm;