import Image from 'next/image';
import React from 'react';

function SocialItem({ href, iconSrc, alt }) {
  return (
    <div>
      <a href={href} target="_blank" rel="noreferrer" aria-label={alt}>
        <Image draggable={false} src={iconSrc} alt={alt} width={24} height={24} />
      </a>
    </div>
  );
}

export default SocialItem;
