import gql from 'graphql-tag';
import { CUSTOMER_FRAGEMENT } from 'helpers/customer';

const CUSTOMER_QUERY = gql`
  ${CUSTOMER_FRAGEMENT}
  query customer($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      ...customer
    }
  }
`;

export default CUSTOMER_QUERY;
