import React, { useContext, useRef} from 'react';
import PropTypes from 'prop-types';
import breakPoints from 'helpers/Styles';
import Cart from 'containers/Cart';
import { StorefrontContext } from 'providers/storefront';
import PreviewMode from 'components/PreviewMode';
import Navigation from './Header/Navigation';
import NavigationMobile from './Header/NavigationMobile';
import Footer from './Footer';
import Head from './Head';
import MobileHeader from './MobileHeader';

const Layout = ({ children, global, preview }) => {
  const { header, footer } = global || {};
  const headerRef = useRef("");
  const { isMenuOpen, setIsMenuOpen } = useContext(StorefrontContext);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <>
      <Head />
      <MobileHeader header={header} toggleMenu={toggleMenu} />
      <NavigationMobile
        header={header}
        isOpen={isMenuOpen}
        onClose={toggleMenu}
      />
      <Navigation header={header} opacityRef={headerRef} />
      <div ref={headerRef}>
        {children}
        <Footer footer={footer} />
        <Cart />
        <PreviewMode preview={preview} />
      </div>
      <style jsx>
        {`
          @media (max-width: ${breakPoints.medium}) {
            .header-nav {
              display: none !important;
            }
          }
        `}
      </style>
    </>
  );
};

Layout.propTypes = {
  preview: PropTypes.bool,
};

Layout.defaultProps = {
  preview: false,
};

export default Layout;
